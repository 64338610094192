<template>
  <div class="content">

    <el-row class="bgW">
      <el-col :span="22">
        <!--检索条件-->
        <el-form class="search" :inline="true" label-suffix="：" size="mini" :model="search">
          <el-form-item label="商品名称">
            <el-input
              v-model="search.name"
              maxlength="30"
              placeholder="请输入商品名称"
            />
          </el-form-item>
          <el-form-item label="状态">
            <el-select
              v-model="search.status"
              clearable
              placeholder="请选择状态"
            >
              <el-option
                v-for="item in options.status"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="2" style="text-align: right;">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
      </el-col>
    </el-row>
    <div class="accountCon">
      <!-- <el-button v-if="!userInfo.shop_id" type="primary" :disabled="!idList.length" class="mb10" size="mini" @click="tapBtn('export')">导出数据</el-button> -->
      <!--列表-->
      <el-row>
        <el-col :span="24">
          <el-table
            :data="list"
            size="small"
            border
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
            @selection-change="handleSelectionChange"
          >
            <!-- <ElTableColumn v-if="!userInfo.shop_id" type="selection" width="50" /> -->
           <ElTableColumn label="商品名称" prop="goods_name" />
<!--            <ElTableColumn label="分类名称" prop="category_name" />
            <ElTableColumn label="商品规格"  prop="spec_name" />
            <ElTableColumn label="价格(元)"  prop="goods_price" />
            <ElTableColumn label="货号"  prop="goods_sn" /> -->

          <ElTableColumn label="积分"  prop="price" />
            <ElTableColumn label="状态">
              <template slot-scope="{ row }">
                <div v-if="row.status == 1">上架</div>
                <div v-if="row.status == 2">下架</div>
              </template>
            </ElTableColumn>
            <ElTableColumn label="操作"  fixed="right">
              <template
                slot-scope="{ row }"
              >
                <span class="theme mr20" @click="tapBtn('detail',row.id)"> 查看</span>
                <span class="theme mr20" @click="tapBtn('update',row.id)"> 编辑</span>
                <span class="peril" @click="tapBtn('status',row.id)" v-if="row.status==1"> 下架</span>
                <span class="peril" @click="tapBtn('status',row.id)" v-if="row.status==2"> 上架</span>
              </template>
            </ElTableColumn>
          </el-table>
          <pagination
            class="page tc mt10"
            :total="page.total"
            :page.sync="page.page"
            :limit.sync="page.limit"
            @pagination="getList"
          />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import pagination from '@/components/Pagination'
import { pointGoodsList,deleteGoods,exportGoods,pointGoodsStatusUpdate } from '@/api/goods';
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
class Search {
  name = ''; // 名称
  status = ''; // 状态
}
class Options {
  constructor () {
    this.status = [
      { id: 1, name: '销售中' },
      { id: 2, name: '已下架' }
    ]
  }
}
export default {
  name: 'PointGoodsList',
  components: {
    pagination
  },
  data () {
    return {
      search: new Search(), // 检索条件
      options: new Options(), // 选项
      page: new Page(), // 分页
      list: [], // 数据展示
    }
  },
computed: {
    ...mapGetters(['userInfo']),
  },
  mounted () {
    this.getList()
  },
  methods: {
    // 获取列表
    getList (option) {
      if (option === 'search') this.page = new Page();
      pointGoodsList({...this.search,...this.page}).then(res => {
        console.log(res);
        this.list = res.data;
        this.page.total = res.total;
      })
    },
    // 点击按钮
    tapBtn (type,id) {
       switch (type) {
        case 'detail':
          this.$router.push('./pointGoodsDetail?id=' + id)
          break
        case 'update':
          this.$router.push('./editPointGoods?id=' + id)
          break
        case 'status':
          this.StatusUpdate(id)
          break
      }
    },
    //修改状态
    StatusUpdate(id){
      pointGoodsStatusUpdate({id:id}).then(res=>{
        if(res.code==200)
        {
          this.$message({
            message: '修改完成',
            type: 'success'
          });
          this.getList();
        }else{
          this.$message.error('修改失败');
        }
      })
    }


  }
}
</script>

<style lang="scss" scoped>

.bgW {
  background: #fff;
  border-radius: 3px;
  margin-bottom: 20px;
}
.el-form-item {
  margin: 5px  40px  3px 0 ;
}
.accountCon{
    background: #fff;
    padding: 10px 0px 80px;
}
</style>
